import './navbar.css'

const NavBar = () => {
  const scrollToAnchor = (a) => {
    const anchor = document.querySelector(a);
    console.log(a)
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id='navbar'>
        <span>
          <strong>
            <a href='/'>HOME</a>
          </strong>
        </span>
        <ul>
            <li>
              <a href="#welcome" onClick={() => scrollToAnchor('#welcome')}>ABOUT</a>
            </li>
            <li>
              <a href="#skills" onClick={() => scrollToAnchor('#skill-section')}>SKILLS</a>
            </li>
            <li>
              <a href="#projects" onClick={() => scrollToAnchor('#project-section')}>PROJECTS</a>
            </li>
            <li>
              <a href="#tools" onClick={() => scrollToAnchor('#tools-section')}>TOOLS</a>
            </li>
            <li>
              <a href="#contact" onClick={() => scrollToAnchor('#contact')}>CONTACT</a>
            </li>
        </ul>
    </div>
  )
}

export default NavBar
