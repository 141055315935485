import './contact.css';
import Button from './Button';

const Contact = () => {
  return (
    <div id='contact'>
        <div>
            <h2>Get in contact with me</h2>
        </div>
        <Button text='CONTACT ME' buttonType='secondary-btn' url='mailto:carsten@carsten-linder.de'/>
    </div>
  )
}

export default Contact
