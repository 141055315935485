import './hero.css';
import Button from './Button';

const Hero = () => {
  return (
    <section id='hero'>
      <div id='hero-content'>
        <h2>Frontend Developer</h2>
        <h1>Carsten Linder</h1>
        <div id='border'></div>
        <p>Creative mind on a coding journey to help others with solutions to my daily problems. Always open to learn new stuff!</p>
        <div id='hero-buttons'>
            <Button text='MY PORTFOLIO' buttonType='primary-btn' url="#project-section"/>
            <Button text='ABOUT ME' buttonType='secondary-btn' url="welcome"/>
            
        </div>
      </div>
      <div id='avatar'>
            <img
                src='./assets/avatar.jpg'
                alt='Avatar'
            />
        </div>
    </section>
  )
}

export default Hero
