import './project.css'
import Button from './Button'

const Project = (props) => {
  return (
    <div id='proj-flip'>
        <div className='flip-card-inner'>
            <div className='flip-card-front'>
                <img
                    src={props.image}
                    alt={props.title}
                    />
            </div>
            <div className='flip-card-back'>
                <h4>{props.title}</h4>
                <p>{props.text}</p>
                <Button text='Github' buttonType='primary-btn' url={props.url}/>
            </div> 
        </div>
      
    </div>  
  )
}

export default Project
