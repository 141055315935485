import './skills.css'
import Skill from './Skill'
import { useState } from 'react';

const skillArray = {
    'HTML': ['Semantic HTML'],
    'CSS': ['Grid & Flexbox', 'Animations & Transitions', 'Bootstrap & Bulma'],
    'JavaScript': ['DOM Manipulation', 'ES6+ (Arrow functions, asynch/await & destructuring)', 'Event handling'],
    'Responsive Web Design': ['Media Queries', 'Viewport Units'],
    'API Integration': ['Fetch API', 'RESTful API'],
    'React': ['Components', 'useState & useEfect'],
}

const Skills = () => {
    const [filter, setFilter] = useState('');

    const handleChange = ({target}) => setFilter(target.value);

  return (
    <div id='skills'>
       
        <input value={filter} onChange={handleChange} placeholder='Search skill'/>

        <div id='skills-block'> 
            {filter === '' ? 
                Object.keys(skillArray).map(skill => 
                <Skill title={skill} text={skillArray[skill]}/>) 
                    :
                Object.keys(skillArray).filter(skill => skill.toLowerCase().includes(filter.toLowerCase())).map(skill => 
                <Skill title={skill} text={skillArray[skill]}/>
            )}
        </div>
        
    </div>
  )
}

export default Skills
