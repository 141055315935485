import './welcome.css'
import Button from './Button';

const Welcome = () => {
  return (
    <div id='welcome'>
      <div className='left'>
        <h3>WELCOME TO MY</h3>
        <h2>PORTFOLIO PAGE</h2>
        <div id='border'></div>
        <p>This website is intended to provide you insights into my projects.</p>
      </div>
      <div className='right'>
        <p>My motivation, after studying biology, on my self-directed journey to learn front-end development stems from my desire to expand my skill set and explore new horizons.</p> 
        <p>I am driven by the curiosity to learn how things work and a deep desire to help others with my coding solutions.</p> 
        <p>I am motivated by the prospect of leveraging my anlytical thinking to create interactive and informative websites, apps, or tools that can enhance the life of everyone.</p> 
        <p>This journey represents a passionate pursuit of interdisciplinary growth, aiming to harness the power of front-end development to make a meaningful impact.</p>
        <div id='welcome-buttons'>
          <Button text='GITHUB' buttonType='primary-btn' url='https://github.com/MyNameIsCarsten'/>
          <Button text='LINKEDIN' buttonType='primary-btn' url='https://www.linkedin.com/in/carsten-linder/'/>
        </div>
        
      </div>
    </div>
  )
}

export default Welcome
