import './imprint.css'

const Imprint = () => {
  return (
    <div id='imprint'>
        <h1>Imprint</h1>
        <p>Carsten Linder</p>
        <p>Rudoolf-Virchow-Straße 29</p>
        <p>32427, Minden</p>
        <br></br>
        <p>E-Mail: Carsten at Carsten-Linder.de</p>
        <br></br>
        <p>Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV:</p>
        <p>Carsten Linder (Anschrift wie oben)</p>
        <a href='/'>Go Back</a>
    </div>
  )
}

export default Imprint
