import './projects.css'
import Project from './Project'

const projectsArray = {
    'German Cookie Banner': {
        text: 'This is a simple cookie banner for german websites that is injected via JavaScript, if the user has not yet seen the banner or selected a cookie choice.',
        url: 'https://github.com/MyNameIsCarsten/german-cookiebanner',
        image:'./assets/cookie-banner.jpg'
    },
    'Beatstars Uploader': {
        text: 'Python app for handling the beat upload to beatstars.com and creating two video files (full video and short) for uploading your beats to YouTube.',
        url: 'https://github.com/MyNameIsCarsten/beatstars-upload',
        image:'./assets/beatstars-uploader.jpg'
    },
    'Django - Social Network': {
        text: 'Social network "Dwitter" build with Django and styled with Bulma CSS Framework. This project is based on the tutorial on realpython.com',
        url: 'https://github.com/MyNameIsCarsten/django-social',
        image:'./assets/django-social.jpg'
    },
    'Django - Ecommerce Store': {
        text: 'Fully functional Django-based ecommerce website with user and guest checkout capabilities. Payments are processed via PayPal.',
        url: 'https://github.com/MyNameIsCarsten/django-ecommerce',
        image:'./assets/django-store.jpg'
    },
    'Tool for chemical calculations': {
        text: 'This is a simple HTML, CSS and JavaScript based calculator tool for calculating various components of chemical and biological equatatios.',
        url: 'https://github.com/MyNameIsCarsten/chemical-calculator',
        image:'./assets/chemical-tool.jpg'
    },
}


const Projects = () => {
  return (
    <div id='projects'>
        <div id='projects-content'>
            {Object.keys(projectsArray).map(p => 
                <Project title={p} text={projectsArray[p].text} url={projectsArray[p].url} image={projectsArray[p].image}/>)}
        </div>
    </div>
  )
}

export default Projects
