import './skill.css'

const Skill = (props) => {
  return (
    <div id='skill-col'>
      <h4>{props.title}</h4>
      <ul>
        {props.text.map(t => <li key={t}>{t}</li>)}
      </ul>
    </div>
  )
}

export default Skill
