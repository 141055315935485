import './App.css';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Welcome from './components/Welcome';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Tools from './components/Tools';
import Contact from './components/Contact';
import Impressum from './components/Impressum';
import Head from './components/Head';
import Imprint from './components/Imprint';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/" element={
          <>
            <Hero />
            <Welcome />
            <section id='skill-section'>
              <Head small='MY SKILLS' big='SELECTION OF MY SKILLS'/>
              <Skills />
            </section>
            <section id='project-section'>
              <Head small='MY PROJECTS' big='SELECTION OF MY PROJECTS'/>
              <Projects />
            </section>
            <section id='tools-section'>
              <Head small='MY TOOLS' big='SELECTION OF THE TOOLS I USE'/>
              <Tools />
            </section>
            <Contact />
            <section  id='imprint-section'>
              <Link to="/impressum">Impressum</Link>
            </section>
            
          </>
        } />
        
        <Route path="/impressum" element={<Imprint />}> 
        </Route>
        
      </Routes>
      
    </Router>
  );
}

export default App;
