import './head.css'


const Head = (props) => {
  return (

    <div className='head'>
        <h3>{props.small}</h3>
        <h2>{props.big}</h2>
        <div id='border'></div>
    </div>

  )
}

export default Head
