import './button.css'

const Button = (props) => {

  const handleClick = () => {
    window.location.href = props.url;
  };

  return (
    <button id={props.buttonType} onClick={handleClick}>
      {props.text}
    </button>
  )
}

export default Button
